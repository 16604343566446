/* eslint-disable max-lines */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { uniqBy } from 'lodash';
import { useCart } from '@fingermarkglobal/cart';
import { useLocaleSelection } from '../../generic/locale-selection';
import { translateProductName } from '../../../utilities/product/translation';
import {
  validatedOrderPayloadState,
  orderTotalState,
  orderCountState,
  totalValidatedState,
  removedUpsellState,
  formattedAddedUpsell,
  categoriesState,
  openCondAddonModalState,
  sessionState,
  offersAndRewardsState,
} from '@fingermarkglobal/atoms';
import { useSecondaryCart } from '@fingermarkglobal/cart';
import { useRestaurantMenu } from '../../../hooks/restaurant/menu';

const useRestaurantCart = () => {
  const { products } = useRestaurantMenu();
  const { cart = [], items = [], commitAdditionalBagFee } = useCart();
  const [cartUpdateCount, setCartUpdateCount] = useState(0);
  const [total, setTotal] = useRecoilState(orderTotalState);
  const [count, setCount] = useRecoilState(orderCountState);
  const offersAndRewards = useRecoilValue(offersAndRewardsState);
  const [totalValidated, setTotalValidated] = useRecoilState(totalValidatedState);
  const setRemovedUpsells = useSetRecoilState(removedUpsellState);
  const setOpenCondAddonModal = useSetRecoilState(openCondAddonModalState);
  const formattedAddedUpsells = useRecoilValue(formattedAddedUpsell);
  const categories = useRecoilValue(categoriesState);
  const session = useRecoilValue(sessionState);

  // Compute hidden products that are conditional add-ons
  const hiddenProducts = useMemo(
    () =>
      categories
        ?.filter(
          category =>
            // Filter out visible categories and the cross-sell category
            !category.isVisible && category.id !== process.env.POI_APP_CROSS_SELL_CATEGORY_ID,
        )
        .flatMap(category => category.products),
    [categories],
  );

  /**
   * Executes the product updater function and add 1 to the update count.
   * The update count is used to check if the user made some changes on the current cart items.
   * @param {() => {}} productUpdater Product updater function. ie: add(), remove()
   * @param {any} params Object params to pass through
   */
  const onCartUpdateHandler = useCallback(
    (productUpdater, isRemoveAll = false) => params => {
      if (!!productUpdater) productUpdater(params);
      setCartUpdateCount(cartUpdateCount + 1);
      if (isRemoveAll) setTotalValidated(false);
    },
    [cartUpdateCount, setTotalValidated],
  );

  const isCartValidated = useMemo(() => !cart.some(item => !item.validated), [cart]);
  const isCartProductsAvailable = useMemo(
    () =>
      !cart.some(item => {
        const { isAvailable = true } = item || {};
        return !isAvailable;
      }),
    [cart],
  );
  const isCartEvaluated = useMemo(() => !cart.some(item => !item.evaluated), [cart]);

  const formattedCart = useMemo(() => {
    return cart.map(item => {
      return {
        ...item,
        add: onCartUpdateHandler(item.add),
        remove: onCartUpdateHandler(item.remove),
        removeAll: onCartUpdateHandler(item.removeAll, true),
      };
    });
  }, [cart, onCartUpdateHandler]);

  // You might be wondering why we have two calls to commitAdditionalBagFee in two different useEffects.
  // The answer is that with the existing logic, when adding a combo item to the cart, two bags were being added.
  // This specifically occurred for combo products. Simple, grouped, and customize products didn't encounter this issue.
  // Nevertheless, a task has been created to revisit this point in the future: https://fingermarkglobal.atlassian.net/browse/S2UI-662
  useEffect(() => {
    commitAdditionalBagFee({ products, session });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isCombo = items?.some(item => `${item?.product?.type}` === `combo`);
    if (!isCombo) {
      commitAdditionalBagFee({ products, session });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, products, session, commitAdditionalBagFee]);

  useEffect(() => {
    const evaluatedCount = cart.reduce((result, item) => result + item.count.current, 0);
    setCount(evaluatedCount);

    if (evaluatedCount === 0) {
      setTotal(0);
      return;
    }

    /**
     * There will be situations where the validated total (from pre order response)
     * won't be the same as the calculated total (all products sum).
     * For these situations we should ignore the calculated total and set the validated total
     * but only if all items are validated, which means that none of them have been changed by the user
     * after the pre order response.
     */
    if (!isCartValidated || !totalValidated) {
      const calculatedRawTotal = cart.reduce(
        (result, current) => result + current.price.current,
        0,
      );
      setTotal(calculatedRawTotal);
    }
  }, [cart, setTotal, setCount, isCartValidated, totalValidated, count]);

  useEffect(() => {
    /**
     * Remove hidden upsell products once the main products is removed from the cart.
     */
    const upsellsToRemove = [];
    cart
      .filter(item => item.isUpsell && hiddenProducts.includes(item.productId))
      ?.forEach(upsell => {
        const mainProducts = formattedAddedUpsells[upsell.productId] || [];
        const mainProductIds = mainProducts.map(item => item.productId);
        if (!cart.some(item => mainProductIds.includes(item.productId))) {
          upsellsToRemove.push({ upsell, mainProducts: uniqBy(mainProducts, 'productId') });
        }
      });

    if (upsellsToRemove.length) {
      upsellsToRemove.forEach(item => item.upsell.removeAll());
      setRemovedUpsells(upsellsToRemove);
      setOpenCondAddonModal(true);
    }
  }, [cart, formattedAddedUpsells, setRemovedUpsells, setOpenCondAddonModal, hiddenProducts]);

  const findOfferInCart = useCallback(
    cartItems =>
      cartItems?.find(cartItem =>
        offersAndRewards?.offers.some(offer => offer.promoId === cartItem.providerData.PromoId),
      ),
    [offersAndRewards],
  );

  const offerInCart = useMemo(() => formattedCart.length > 0 && findOfferInCart(formattedCart), [
    formattedCart,
    findOfferInCart,
  ]);

  return {
    count,
    total,
    onCartUpdateHandler,
    cartUpdateCount,
    isCartValidated,
    isCartProductsAvailable,
    formattedCart,
    items,
    isCartEvaluated,
    offerInCart,
  };
};

const useRestaurantSecondaryCart = () => {
  const { secondaryCart = [], push, pull, resetSecondaryCart } = useSecondaryCart();
  const [secondaryCartUpdateCount, setSecondaryCartUpdateCount] = useState(0);
  const setTotal = useSetRecoilState(orderTotalState);
  const setCount = useSetRecoilState(orderCountState);
  const setRemovedUpsells = useSetRecoilState(removedUpsellState);
  const setOpenCondAddonModal = useSetRecoilState(openCondAddonModalState);
  const formattedAddedUpsells = useRecoilValue(formattedAddedUpsell);
  const validatedOrderPayload = useRecoilValue(validatedOrderPayloadState);
  const { cart: validatedCart } = validatedOrderPayload || {};
  const { total: validatedRawTotal } = validatedCart || {};
  const { i18n } = useLocaleSelection();
  const { language } = i18n || {};
  const translateInline = translateProductName(language);
  const categories = useRecoilValue(categoriesState);

  const hiddenProducts = useMemo(
    () =>
      categories?.filter(category => !category.isVisible).flatMap(category => category.products),
    [categories],
  );

  const isSecondaryCartValidated = useMemo(() => !secondaryCart.some(item => !item.validated), [
    secondaryCart,
  ]);

  const isSecondaryCartEvaluated = useMemo(() => !secondaryCart.some(item => !item.evaluated), [
    secondaryCart,
  ]);

  const total = useMemo(() => {
    // Same rule as the main cart total about validated total
    if (isSecondaryCartValidated && validatedRawTotal)
      return Math.round(validatedRawTotal * 100) / 100;

    const rawTotal = secondaryCart.reduce((result, current) => result + current.price.current, 0);
    return Math.round(rawTotal * 100) / 100;
  }, [isSecondaryCartValidated, secondaryCart, validatedRawTotal]);

  const count = useMemo(
    () => secondaryCart.reduce((result, item) => result + item.count.current, 0),
    [secondaryCart],
  );

  /**
   * Executes the product updater function and add 1 to the update count.
   * The update count is used to check if the user made some changes on the current cart items.
   * @param {() => {}} productUpdater Product updater function. ie: add(), remove()
   * @param {any} params Object params to pass through
   */
  const onCartUpdateHandler = useCallback(
    productUpdater => params => {
      if (!!productUpdater) productUpdater(params);
      setSecondaryCartUpdateCount(secondaryCartUpdateCount + 1);
    },
    [secondaryCartUpdateCount],
  );

  const formattedSecondaryCart = useMemo(() => {
    return secondaryCart.map(item => {
      return {
        ...item,
        title: translateInline(item?.name),
        options: item.options.map(option => ({
          ...option,
          products: option.products.map(product => ({
            ...product,
            title: translateInline(product?.name),
            customisations: product.customisations.map(customisation => ({
              ...customisation,
              title: translateInline(customisation?.name),
              products: customisation.products.map(customisationProduct => ({
                ...customisationProduct,
                title: translateInline(customisationProduct?.name),
              })),
            })),
          })),
        })),
        add: onCartUpdateHandler(item.add),
        remove: onCartUpdateHandler(item.remove),
        removeAll: onCartUpdateHandler(item.removeAll),
      };
    });
  }, [secondaryCart, onCartUpdateHandler, translateInline]);

  useEffect(() => {
    const upsellsToRemove = [];
    secondaryCart
      .filter(item => item.isUpsell && hiddenProducts.includes(item.productId))
      ?.forEach(upsell => {
        const mainProducts = formattedAddedUpsells[upsell.productId] || [];
        const mainProductIds = mainProducts.map(item => item.productId);
        if (!secondaryCart.some(item => mainProductIds.includes(item.productId))) {
          upsellsToRemove.push({ upsell, mainProducts: uniqBy(mainProducts, 'productId') });
        }
      });

    if (upsellsToRemove.length) {
      upsellsToRemove.forEach(item => item.upsell.removeAll());
      setRemovedUpsells(upsellsToRemove);
      setOpenCondAddonModal(true);
    }
  }, [
    secondaryCart,
    formattedAddedUpsells,
    setRemovedUpsells,
    setOpenCondAddonModal,
    hiddenProducts,
  ]);

  const formattedPush = () => {
    setTotal(total);
    setCount(count);
    push();
  };

  return {
    count,
    total,
    onCartUpdateHandler,
    secondaryCartUpdateCount,
    isSecondaryCartEvaluated,
    formattedSecondaryCart,
    formattedPush,
    pull,
    resetSecondaryCart,
  };
};

export { useRestaurantCart, useRestaurantSecondaryCart };
